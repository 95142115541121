<template>
  <base-material-card
    color="primary"
    title="Accounting and Billing Information"
  >
    <v-progress-linear
      v-if="fetchingAccountingInfo"
      indeterminate
    />
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="accountingInfo.vrpType"
            label="VRP Type"
            prepend-icon="mdi-notebook"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="accountingInfo.billingAddress"
            label="Billing Address"
            prepend-icon="mdi-map-marker"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="accountingInfo.contractNo"
            label="Contract Number"
            prepend-icon="mdi-file-document-edit"
          />
        </v-col>
        <v-col
          v-for="(dateField, i) in dateFields"
          :key="i"
          cols="12"
          sm="6"
        >
          <v-menu
            :ref="dateField.ref"
            v-model="dateField[dateField.ref]"
            :close-on-content-click="false"
            :return-value.sync="accountingInfo[dateField.value]"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="accountingInfo[dateField.value]"
                :label="dateField.label"
                :prepend-icon="dateField.icon"
                readonly
                clearable
                hide-details
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="accountingInfo[dateField.value]"
              color="primary"
              no-title
              class="datepicker-width"
            >
              <v-spacer />
              <v-btn
                text
                @click="dateField[dateField.ref] = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                @click="$refs[dateField.ref][0].save(accountingInfo[dateField.value])"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="accountingInfo.contractLength"
            label="Contract Length"
            prepend-icon="mdi-briefcase-account"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="accountingInfo.freeYears"
            label="Free Years"
            prepend-icon="mdi-briefcase-account"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="accountingInfo.annualRetainerFee"
            label="Annual Retainer Fee"
            prepend-icon="mdi-briefcase-account"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="accountingInfo.recurring"
            label="Recurring"
            prepend-icon="mdi-briefcase-account"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="accountingInfo.billingMode"
            label="Billing Mode"
            prepend-icon="mdi-receipt"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="accountingInfo.invoiced"
            label="Invoiced ?"
            prepend-icon="mdi-cash-usd"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="accountingInfo.discountable"
            label="Discountable ?"
            prepend-icon="mdi-sale"
          />
        </v-col>
        <v-col
          cols="12"
        >
          <v-textarea
            v-model="accountingInfo.deactivateReason"
            label="Reason for Deactivation"
            prepend-icon="mdi-calendar-start"
          />
        </v-col>
      </v-row>

      <v-btn
        color="success"
        small
        @click="updateBillingInfo"
      >
        <v-icon left>
          mdi-content-save
        </v-icon>
        Save
      </v-btn>
    </v-card-text>
  </base-material-card>
</template>

<script>
  // import axios from 'axios'
  import { mapActions, mapState } from 'vuex'
  // import { checkCUD } from '@/shared/management'

  export default {
    data: () => ({
      loading: false,
      fetchingAccountingInfo: true,
      accountingInfo: {},
      dateFields: [
        { ref: 'lastBilledDateRef', lastBilledDateRef: false, value: 'lastBilledDate', label: 'Last Billed Date', icon: 'mdi-calendar' },
        { ref: 'deactivatedDateRef', deactivatedDateRef: false, value: 'deactivatedDate', label: 'Deactivated Date', icon: 'mdi-calendar' },
        { ref: 'expirationDateRef', expirationDateRef: false, value: 'expirationDate', label: 'Expiration Date', icon: 'mdi-calendar-remove' },
        { ref: 'cancellationDateRef', cancellationDateRef: false, value: 'cancellationDate', label: 'Cancellation Date', icon: 'mdi-calendar-remove-outline' },
        { ref: 'contractSignedDateRef', contractSignedDateRef: false, value: 'contractSignedDate', label: 'Contract Signed Date', icon: 'mdi-calendar-edit' },
        { ref: 'billingStartDateRef', billingStartDateRef: false, value: 'billingStartDate', label: 'Billing Start Date', icon: 'mdi-calendar' },
        { ref: 'automaticBillingStartDateRef', automaticBillingStartDateRef: false, value: 'automaticBillingStartDate', label: 'Automatic Billing Start Date', icon: 'mdi-calendar' },
      ],
    }),
    computed: {
      ...mapState({
        role: state => state.authentication.role,
      }),
    },
    // mounted () {
    //   this.getDataFromApi()
    // },
    methods: {
      ...mapActions({
        showSnackBar: 'showSnackBar',
      }),

      updateBillingInfo () {
        // if (!checkCUD(this.role.id)) {
        //   this.showSnackBar({ text: 'This action is not permitted.', color: 'warning' })
        //   return
        // }
      },

      // getDataFromApi () {},
    },
  }
</script>
